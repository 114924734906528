// Overrides user components-variables
@import "~bootstrap/scss/functions";

@import "../variables/variables";
@import "~bootstrap/scss/variables";
$white-color: (
  "base": #ffffff,
);
$black-color: (
  "base": #000000,
);
$primary-color: (
  "lighten-4": #efdeff,
  "lighten-3": #dcbdff,
  "lighten-2": #c89cff,
  "lighten-1": #b583ff,
  "base": #975aff,
  "darken-1": #7441db,
  "darken-2": #562db7,
  "darken-3": #3b1c93,
  "darken-4": #28117a,
);
$secondary-color: (
  "lighten-4": #eaf0f9,
  "lighten-3": #d6e1f3,
  "lighten-2": #b5c3db,
  "lighten-1": #8f9db8,
  "base": #616d89,
  "darken-1": #465375,
  "darken-2": #303c62,
  "darken-3": #1e294f,
  "darken-4": #121a41,
);
$success-color: (
  "lighten-4": #e0fbda,
  "lighten-3": #bdf8b6,
  "lighten-2": #8eec8d,
  "lighten-1": #6cd975,
  "base": #40c057,
  "darken-1": #2ea54f,
  "darken-2": #208a47,
  "darken-3": #146f3e,
  "darken-4": #0c5c38,
);
$danger-color: (
  "lighten-4": #fee8dc,
  "lighten-3": #fecbba,
  "lighten-2": #fca897,
  "lighten-1": #f9877c,
  "base": #f55252,
  "darken-1": #d23b48,
  "darken-2": #b02940,
  "darken-3": #8e1a38,
  "darken-4": #750f33,
);
$warning-color: (
  "lighten-4": #feefd0,
  "lighten-3": #fedba1,
  "lighten-2": #fcc173,
  "lighten-1": #faa750,
  "base": #f77e17,
  "darken-1": #d46010,
  "darken-2": #b1460b,
  "darken-3": #8f2f07,
  "darken-4": #762004,
);
$info-color: (
  "lighten-4": #d5f1fd,
  "lighten-3": #ace0fc,
  "lighten-2": #81c8f7,
  "lighten-1": #60aff0,
  "base": #2f8be6,
  "darken-1": #226cc5,
  "darken-2": #1750a5,
  "darken-3": #0e3885,
  "darken-4": #09276e,
);
$light: (
  "base": #dbdee5,
);
$dark: (
  "base": #1a051d,
);

$grey-color: (
  "lighten-4": #f5f5f5,
  "lighten-3": #eee,
  "lighten-2": #e0e0e0,
  "lighten-1": #bdbdbd,
  "base": #9e9e9e,
  "darken-1": #757575,
  "darken-2": #616161,
  "darken-3": #424242,
  "darken-4": #212121,
);

$shades: (
  "black": #000000,
  "white": #ffffff,
  "transparent": transparent,
);

$colors: (
  "white": $white-color,
  "black": $black-color,
  "primary": $primary-color,
  "secondary": $secondary-color,
  "success": $success-color,
  "danger": $danger-color,
  "warning": $warning-color,
  "info": $info-color,
  "light": $light,
  "dark": $dark,
  "grey": $grey-color,
);

$gradient-colors: (
  gradient-mint: (
    "start-color": #226cc5,
    "end-color": #6cd975,
  ),
  gradient-pomegranate: (
    "start-color": #465375,
    "end-color": #f55252,
  ),
  gradient-blackberry: (
    "start-color": #303c62,
    "end-color": #b5c3db,
  ),
  gradient-king-yna: (
    "start-color": #7441db,
    "end-color": #f9877c,
  ),
  gradient-ibiza-sunset: (
    "start-color": #b02940,
    "end-color": #fcc173,
  ),
  gradient-flickr: (
    "start-color": #f77e17,
    "end-color": #2f8be6,
  ),
  gradient-purple-bliss: (
    "start-color": #562db7,
    "end-color": #60aff0,
  ),
  gradient-man-of-steel: (
    "start-color": #fbb91d,
    "end-color": #000,
  ),
  gradient-purple-love: (
    "start-color": #7441db,
    "end-color": #c89cff,
  ),
  gradient-starfall: (
    "start-color": #d23b48,
    "end-color": #2f8be6,
  ),
);
